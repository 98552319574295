import React, { useEffect, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Checkbox, FormControlLabel, Modal } from '@material-ui/core'
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import './style.css';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { create_finance, update_finance } from '../store/action';
import { selectFinanceDatas } from '../store/slice';
import { CircularProgress } from '@material-ui/core'
import { selectAgencyDatas } from '../../agency/store/slice';




const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '95%'
        },
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

function FinanceModal({
    modalStatus,
    modalAction,
    data,
    Amount,
    setAmount,
    Description,
    setDescription,
    Type,
    setType,
    Category,
    setCategory,
    ModePay,
    setModePay,
    SubCategory,
    setSubCategory,
    AgencyId,
    setAgencyId,
    FinanceYrId,
    setFinanceYrId,
    search,
    setSearch
}) {

    const classes = useStyles();
    const route_history = useHistory();
    const dispatch = useDispatch();
    const searchcontainer = useRef(null);
    console.log("a",AgencyId)
    const [buttonLoader, setButtonLoader] = useState(false);
    const [isAgencyPayment, setIsAgencyPayment] = useState(AgencyId ? true :false)
    console.log("isAgencyPayment",isAgencyPayment)

    useEffect(() => {
        if(AgencyId){
            setIsAgencyPayment(true)
        }
      }, [AgencyId]);
   


    const [AgencySelectError, setAgencySelectError] = useState("");


    const { arr_category, arr_sub_category, financialYrList, finance_filter_year } = useSelector(selectFinanceDatas);
    const { all_agencts_list } = useSelector(selectAgencyDatas);

    const [Visibility, setVisibility] = useState(false);
    const hideSuggestion = () => setVisibility(false);
    const showSuggestion = () => setVisibility(true);
    const [SuggestionList, setSuggestionList] = useState([]);

    const suggestion = (text) => {
        if (!text) {
            setSuggestionList([]);
        }
        else {

            let matches = all_agencts_list.filter((item) => {
                const regex = new RegExp(`${text}`, "gi");
                return item.consultant_name.match(regex)
            });
            let final_list_data = [];
            matches.map((item_val, length) => {
                // if(length < 4) {
                final_list_data.push(item_val);
                // }
                return null;
            })
            setSuggestionList(final_list_data);
        }
    }

    const handleClose = () => {
        modalAction(false)
        setAmount('')
        setDescription('')
        setType('')
        setCategory('')
        setModePay('')
        setAgencyId(null)
        setFinanceYrId(null)
        setSearch('')
        setAgencySelectError('')
        setIsAgencyPayment(false)

    };

    const buttonClick = () => {
        setButtonLoader(true);
        // let postData = ''
        // if(isAgencyPayment && AgencyId){
        // postData = data ?
        // {
        //     amount: Amount,
        //     description: Description,
        //     finance_type: Type,
        //     category_type: Category,
        //     mode_of_payment: ModePay,
        //     sub_category_type: SubCategory,
        //     agency_id: AgencyId,
        //     financial_year: FinanceYrId ? FinanceYrId : finance_filter_year
        // }
        // :
        // {
        //     amount: Amount,
        //     description: Description,
        //     finance_type: Type,
        //     category_type: Category,
        //     mode_of_payment: ModePay,
        //     sub_category_type: SubCategory,
        //     agency_id: AgencyId,
        //     financial_year: FinanceYrId ? FinanceYrId : finance_filter_year
        // }
        // }
        // else{
        //     setSearch('')
        //     setAgencySelectError('Select an Agent!')
        // }

        if (isAgencyPayment && !AgencyId) {
            setAgencySelectError('Select an Agent!')
            setButtonLoader(false)


        }

        if (isAgencyPayment && !AgencyId)
            return



        let postData = {
            amount: Amount,
            description: Description,
            finance_type: Type,
            category_type: Category,
            mode_of_payment: ModePay,
            sub_category_type: SubCategory,
            agency_id: AgencyId ? AgencyId : "",
            financial_year: FinanceYrId ? FinanceYrId : finance_filter_year
        }

        const success = () => {
            setButtonLoader(false);
            handleClose()
        };
        const failed = () => {
            setButtonLoader(false)
        }
        if (data) {
            update_finance(route_history, dispatch, postData, data.id, success, failed)
        } else {
            create_finance(route_history, dispatch, postData, success, failed)
        }
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            disablePortal
            disableEnforceFocus
            disableAutoFocus
            className={classes.modal}
            open={modalStatus}
            onClose={handleClose}
            closeAfterTransition
        >

            <div className="app-modal-main" style={{ overflowY: 'auto' }}>
                <h4 style={{ margin: 0, marginBottom: 20 }}>{data ? 'Update' : 'Insert'} Record</h4>

                <form className={classes.root} noValidate autoComplete="off">
                    <TextField
                        id="standard-basic"
                        label="Amount"
                        type="number"
                        value={Amount}
                        onChange={txt => setAmount(txt.target.value)}
                    />

                    <TextField
                        id="standard-basic"
                        label="Description"
                        value={Description}
                        onChange={txt => setDescription(txt.target.value)}
                    />

                    <TextField
                        id="standard-basic"
                        select
                        label="Finance Type"
                        value={Type}
                        onChange={txt => setType(txt.target.value)}
                    >
                        <MenuItem value={'Income'}>Income</MenuItem>
                        <MenuItem value={'Expense'}>Expense</MenuItem>
                    </TextField>

                    <TextField
                        id="standard-basic"
                        select
                        label="Category"
                        value={Category}
                        onChange={txt => setCategory(txt.target.value)}
                    >
                        <MenuItem value=''>Choose Category</MenuItem>
                        {
                            arr_category.map((item, key) => {
                                const { id, name } = item;
                                return (
                                    <MenuItem key={key} value={id}>{name}</MenuItem>
                                )
                            })
                        }
                    </TextField>

                    <TextField
                        id="standard-basic"
                        select
                        label="Sub Category"
                        value={SubCategory}
                        onChange={txt => setSubCategory(txt.target.value)}
                    >
                        <MenuItem value=''>Choose Category</MenuItem>
                        {
                            arr_sub_category.map((item, key) => {
                                const { id, name } = item;
                                return (
                                    <MenuItem key={key} value={id}>{name}</MenuItem>
                                )
                            })
                        }
                    </TextField>

                    <TextField
                        id="standard-basic"
                        select
                        label="Mode of pay"
                        value={ModePay}
                        onChange={txt => setModePay(txt.target.value)}
                    >
                        <MenuItem value=''>Choose Mode</MenuItem>
                        <MenuItem value={'Cash'}>Cash</MenuItem>
                        <MenuItem value={'UPI'}>UPI</MenuItem>
                        <MenuItem value={'Cheque'}>Cheque</MenuItem>
                        <MenuItem value={'Bank Transfer'}>Bank Transfer</MenuItem>
                    </TextField>

                    <FormControlLabel
                        control={
                            // <Checkbox checked={isAgencyPayment} onChange={v => {
                            //     if (!isAgencyPayment) {
                            //         setAgencyId('')
                            //     }
                            //     setIsAgencyPayment(!isAgencyPayment)
                            // }} name="agencyPayment" />

                            <Checkbox checked={isAgencyPayment} onChange={v => {
                                const newValue = !isAgencyPayment;
                                setIsAgencyPayment(newValue);
                                setAgencyId(newValue ? AgencyId : ''); // When unchecked, set to empty string
                            }} name="agencyPayment" />

                        }
                        label="Agency Payment"
                    />

                    {isAgencyPayment ?

                        <div style={{ height: "100%", position: 'relative' }} ref={searchcontainer}>
                            <TextField
                                error={AgencySelectError ? true : false}
                                onFocus={()=> setAgencySelectError('')   }
                                type="text"
                                id="standard-basic"
                                label="Agency List"
                                fullWidth
                                value={search}
                                onClick={showSuggestion}
                                onChange={e => { setSearch(e.target.value); suggestion(e.target.value); setAgencyId('') }}
                                helperText={AgencySelectError}
                            />

                            {Visibility && SuggestionList.length > 0 && (
                                <div className="suggestion-main-layout">
                                    <ul className="suggestion-list-container">
                                        {
                                            SuggestionList && SuggestionList.map((item, key) => {
                                                return (
                                                    <>
                                                        <li
                                                            className="student-suggestion-list"
                                                            key={key}
                                                            onClick={() => {
                                                                hideSuggestion();
                                                                setSearch(item.consultant_name);
                                                                setAgencyId(item.id)
                                                            }}
                                                        >
                                                            {item.consultant_name} - ({item.consultancy_name})
                                                        </li>
                                                        <div style={{ height: 0.5, backgroundColor: 'grey' }} />
                                                    </>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            )}

                        </div>


                        : null
                    }
                    <TextField
                        id="standard-basic"
                        select
                        label="Financial Year"
                        value={FinanceYrId ? FinanceYrId : finance_filter_year}
                        onChange={txt => setFinanceYrId(txt.target.value)}
                    >
                        <MenuItem value=''>Choose Financial Year</MenuItem>
                        {
                            financialYrList && financialYrList.map((item, k) => {
                                // console.log('asdf',item)
                                return (<MenuItem value={item.id}>{item.year}</MenuItem>)
                            })
                        }
                    </TextField>

                </form>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <button

                        onClick={buttonClick}
                        className={buttonLoader ? "submit-btn disabled-btn" : "submit-btn"} >
                        {buttonLoader ? (<CircularProgress size={20} color="inherit" />) : (data ? 'Update' : 'Create')}

                    </button>
                </div>

            </div>
        </Modal>
    );
}



export default FinanceModal
